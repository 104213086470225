@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --font-primary: "Poppins", sans-serif;
  --my-black: #1d1d24;
  --color-gray: #757575;
  --line-height-b1: 1.67;
  --color-opacity-1: hsla(0, 0%, 100%, 0.2);
  --my-bright-color: #fd4766;
  --my-bright-color-2: #478dfd;
  --section-color-1: white;
  --section-color-2: #fafafb;
  --card-color-1: #f6f6f6;
  --card-color-2: #f6f6f6;
  --wechat-green: #09b83e;
  --wechat-green-fade: rgb(135, 218, 160);
  --venmo-blue: #008cff;
  --venmo-blue-fade: #9ccef8;
  --paypal-blue: #00457c;
  --paypal-blue-fade: #6795bb;
  --other-gray: #757575;
  --other-gray-fade: #b0acac;
}

body {
  font-family: var(--font-primary);
  transition: all 0.2s ease-in-out;
}

.btn-book {
  border: 2px solid;
  padding: 13px 30px;
  line-height: var(--line-height-b1);
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
  display: inline-block;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
}
.navbar .btn-book {
  color: var(--my-black);
  background-color: transparent;
}
.navbar .btn-book:hover {
  background-color: var(--my-black);
}
.brand-name .myname {
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 1;
  /* Fallback: Set a background color. */
  background-color: white;
  /* Create the gradient. */
  background: linear-gradient(120deg, #f3b77e 20%, #fd4766 90%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.navbar-text {
  padding: 0;
}

.navbar-text a:hover {
  color: white;
  background-color: var(--my-bright-color);
}

.nav-link {
  color: var(--my-black);
  font-size: 16px;
  font-weight: 500;
}
.nav-link:hover {
  color: var(--my-bright-color);
}

.navbar-toggler {
  border: none;
}
.navbar-toggler:focus {
  box-shadow: none;
}
.navbar-toggler:hover {
  background-color: rgb(210, 209, 209);
}

footer {
  padding: 40px 0;
  background-color: var(--my-black);
  background: linear-gradient(120deg, #142a3c 20%, #1d1b2b 80%);
  color: white;
}

footer .subtitle {
  font-size: 20px;
  font-weight: 500;
}
footer .name {
  font-size: 34px;
  font-weight: 600;
  /* Fallback: Set a background color. */
  background-color: white;
  /* Create the gradient. */
  background: linear-gradient(120deg, #1abbf6 20%, #0464da 90%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}
footer .lifecoach {
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  /* Fallback: Set a background color. */
  background-color: white;
  /* Create the gradient. */
  background: linear-gradient(120deg, #0464da 20%, #ec4f89 90%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}
footer .details,
footer .social-link {
  line-height: 2;
  font-size: 14px;
  font-weight: 400;
  color: rgb(211, 211, 211);
}
footer .details svg,
footer .social-link svg {
  width: 30px;
  line-height: 1.5;
}
footer .social-link {
  cursor: pointer;
}
section {
  padding-top: 60px;
  padding-bottom: 60px;
}

.headline {
  padding-bottom: 60px;
  animation: customOne 1s ease-in-out 0s 1 normal none running;
  /* Fallback: Set a background color. */
  background-color: #e4574f;
  /* Fallback: gradient. */
  background: linear-gradient(190deg, #edb65d 0%, #e4574f 50%, #fd4766 80%);
  /* Image with gradient. */
  background: linear-gradient(
      190deg,
      rgba(237, 182, 93, 1) 0%,
      rgba(228, 87, 79, 0.95) 50%,
      rgba(253, 71, 102, 1) 85%
    ),
    url("images/headline_bg1.png");
}

.headline-img {
  margin: 4px;
  background-image: url("images/yuye_photo_1.jpg");
  background-size: cover;
  background-position: 70% 70%;
  background-repeat: no-repeat;
  padding: 200px 200px;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.headline-sub {
  color: white;
  font-size: 24px;
  line-height: 40px;
  font-weight: 300;
}

.theme-gradient {
  /* Fallback: Set a background color. */
  background-color: var(--my-bright-color);
  /* Create the gradient. */
  background-image: linear-gradient(
    120deg,
    #1c99fe 20.69%,
    #7644ff 50.19%,
    #fd4766 79.69%
  );
  background: linear-gradient(
    120deg,
    #1c99fe 20.69%,
    #7644ff 50.19%,
    #fd4766 79.69%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.headline .slogan {
  color: white;
  font-size: 50px;
  line-height: 75px;
  font-weight: 800;
  font-family: Montserrat, sans-serif;
}
.headline .btn-book {
  margin: 20px 12px;
  padding-left: 60px;
  padding-right: 60px;
  color: white;
  border-color: var(--my-black);
  background-color: var(--my-black);
}

.headline .btn-book:hover {
  border-color: white;
}

.section-subtitle {
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-size: 16px;
  border-radius: 4px;
  margin: 12px 0;
}
.section-title {
  line-height: 1.3;
  margin-bottom: 35px;
  font-size: 50px;
  font-weight: 600;
}
.section-subtitle2 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4074;
  color: #1f1f25;
  margin-top: 20px;
  margin-bottom: 16px;
}
.details {
  font-size: 18px;
  line-height: 30px;
  color: var(--color-gray);
}

ul.areas {
  list-style-type: circle;
}
ul.areas li {
  font-weight: 400;
  color: var(--color-gray);
}

.service-img {
  background-image: url("images/yuye_profile.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 200px 100px;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 4px;
}

section.how {
  background-color: var(--section-color-2);
}
.how .how-col {
  margin: 10px 0;
}
.how-steps {
  height: 100%;
}
.how-steps .step-num {
  font-size: 50px;
  font-weight: 700;
  line-height: 1;
}

.how-steps .step-num.step1 {
  /* Fallback: Set a background color. */
  background-color: var(--my-black);
  /* Create the gradient. */
  background: linear-gradient(120deg, #ff8a9d 30%, #fd627c 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.how-steps .step-num.step2 {
  /* Fallback: Set a background color. */
  background-color: var(--my-black);
  /* Create the gradient. */
  background: linear-gradient(120deg, #bef3eb 30%, #57c1ec 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.how-steps .step-num.step3 {
  /* Fallback: Set a background color. */
  background-color: var(--my-black);
  /* Create the gradient. */
  background: linear-gradient(120deg, #a6c3f1 30%, #478dfd 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.how-steps ul {
  list-style-type: circle;
}
.step-title {
  font-weight: 700;
  letter-spacing: 0.5px;
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 8px;
}
.step-subtitle {
  margin-bottom: 8px;
}
.how-steps li {
  font-size: 16px;
  font-weight: 400;
  color: var(--color-gray);
}

.how-steps {
  background-color: white;
  padding: 16px;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.how-steps .btn-book {
  border-color: white;
  color: white;
}
.how-steps .btn-book:hover {
  border-color: var(--my-black);
  color: var(--my-black);
}

.how-steps .btn-book.book-sample {
  background-color: #fb627b;
}
.how-steps .btn-book.book-plan {
  background-color: #70c8d0;
}

.how-steps .btn-book.book-coaching {
  background-color: #6ba2fa;
}
.icon-check {
  color: #58aa7e;
}
.icon-cross {
  color: #ef5e5e;
}

.icon-check,
.icon-cross {
  margin-right: 16px;
}

section.pricing {
  background-color: var(--section-color-1);
}
.pricing .pricing-card {
  background-color: var(--card-color-2);
  padding: 18px 30px;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}
.pricing .price-may-change {
  margin-top: 20px;
  color: var(--color-gray);
  font-size: 14px;
}

.pricing-card .subtitle {
  font-size: 36px;
  font-weight: 700;
  color: var(--my-black);
}
.pricing-card .include {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--my-black);
  margin-top: 8px;
  margin-bottom: 8px;
}
.pricing-card .best-who {
  font-weight: 500;
  color: #7ba5b9;
}
.pricing-card .details {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}
.pricing .pricing-card svg {
  margin-left: 12px;
  margin-right: 12px;
  width: 12px;
}

.pricing-card .price-avg {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: gray;
}

.pricing-card .btn-sampleplan,
.pricing-card .btn-onetime,
.pricing-card .btn-fourtime,
.pricing-card .btn-sixtime,
.pricing-card .btn-twelvetime,
.pricing-card .btn-discount {
  /* Fallback: Set a background color. */
  background-color: var(--my-black);
  /* Create the gradient. */
  background: linear-gradient(120deg, #4c4c4c 20%, #303030 90%);
  color: white;
  border: 2px solid;
  line-height: 1.25;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  box-sizing: border-box;
  padding: 12px 32px;
  margin-top: 12px;
  border-color: #4c4c4c;
}
.pricing-card .btn-sampleplan:hover,
.pricing-card .btn-onetime:hover,
.pricing-card .btn-fourtime:hover,
.pricing-card .btn-sixtime:hover,
.pricing-card .btn-twelvetime:hover,
.pricing-card .btn-discount:hover {
  background-color: var(--my-black);
  background: linear-gradient(120deg, #f44f5a 20%, #5638d9 90%);
  color: white;
  border-color: var(--my-black);
}

.pricing-card .price-num {
  font-size: 50px;
  font-weight: 700;
  line-height: 1.25;
}
.pricing-card.sample-session .price-num,
.pricing-card.sample-session .price-avg {
  /* Fallback: Set a background color. */
  background-color: var(--my-black);
  /* Create the gradient. */
  background: linear-gradient(120deg, #34c7cf 30%, #0351ab 79.69%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}
.pricing-card.single-session .price-num,
.pricing-card.single-session .price-avg {
  /* Fallback: Set a background color. */
  background-color: var(--my-black);
  /* Create the gradient. */
  background: linear-gradient(120deg, #aebdc9 30%, #426291 79.69%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.pricing-card.four-session .price-num,
.pricing-card.four-session .price-avg {
  /* Fallback: Set a background color. */
  background-color: var(--my-black);
  /* Create the gradient. */
  background: linear-gradient(120deg, #59aef3 30%, #2f5cef 79.69%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}
.pricing-card.six-session .price-num,
.pricing-card.six-session .price-avg {
  /* Fallback: Set a background color. */
  background-color: var(--my-black);
  /* Create the gradient. */
  background: linear-gradient(120deg, #f5798d 40%, #6a44f5 90%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.pricing-card.twelve-session .price-num,
.pricing-card.twelve-session .price-avg {
  /* Fallback: Set a background color. */
  background-color: var(--my-black);
  /* Create the gradient. */
  background: linear-gradient(120deg, #10cfe0 30%, #3f7eeb 79.69%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.pricing-card .popular {
  background-color: var(--my-bright-color);
  background: linear-gradient(120deg, #f88e77 30%, #f67577 79.69%);
  padding: 0 12px;
  margin-left: 10px;
  border-radius: 0.3em;
  color: white;
  font-size: 18px;
  font-weight: 600;
}
.pricing .sample-session {
  background: linear-gradient(120deg, #e0e0e0 30%, #dadada 79.69%);
}
.pricing .discount {
  background: linear-gradient(120deg, #ffa372 30%, #f89858 79.69%);
}
.pricing .discount .subtitle {
  color: white;
}
.pricing .discount .details {
  color: white;
  font-weight: 400;
}

.wechat-modal .wechat-qr {
  background-image: url("images/yuye_wechat_qr.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 200px;
  height: 200px;
}

.payment-modal .modal-content {
  padding: 20px;
  border-radius: 1.5rem;
}

.payment-modal .modal-header,
.payment-modal .modal-footer,
.wechat-modal .modal-header,
.wechat-modal .modal-footer {
  border: none;
}

.wechat-modal .modal-body {
  padding-top: 0;
  padding-bottom: 0;
}
.payment-modal .subtitle,
.wechat-modal .subtitle {
  font-size: 24px;
  font-weight: 600;
}
.payment-modal .detail,
.wechat-modal .detail {
  color: var(--color-gray);
}
.payment-modal .modal-footer button,
.wechat-modal .modal-footer button {
  padding: 8px 20px;
  border-radius: 2em;
}
.payment-modal .detail {
  font-size: 16px;
}
.payment-modal .amount-num {
  font-weight: 600;
}
.payment-modal .btn-payment {
  font-size: 14px;
  font-weight: 700;
  width: 100px;
  margin: 10px;
  padding: 10px;
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.payment-modal .btn-payment.venmo.light {
  background-color: var(--venmo-blue-fade);
}
.payment-modal .btn-payment.venmo,
.payment-modal .btn-payment.venmo:hover {
  background-color: var(--venmo-blue);
}
.payment-modal .btn-payment.paypal.light {
  background-color: var(--paypal-blue-fade);
}
.payment-modal .btn-payment.paypal,
.payment-modal .btn-payment.paypal:hover {
  background-color: var(--paypal-blue);
}
.payment-modal .btn-payment.wechat.light {
  background-color: var(--wechat-green-fade);
}
.payment-modal .btn-payment.wechat,
.payment-modal .btn-payment.wechat:hover {
  background-color: var(--wechat-green);
}
.payment-modal .btn-payment.other.light {
  background-color: var(--other-gray-fade);
}
.payment-modal .btn-payment.other,
.payment-modal .btn-payment.other:hover {
  background-color: var(--other-gray);
}
.payment-modal .instruction {
  font-size: 24px;
  font-weight: 800;
}
.payment-modal .instruction.venmo {
  color: var(--venmo-blue);
}
.payment-modal .instruction.wechat {
  color: var(--wechat-green);
}
.payment-modal .instruction.paypal {
  color: var(--paypal-blue);
}
.payment-modal .instruction.other {
  color: var(--other-gray);
}
.payment-modal .payment-method.detail {
  margin-top: 20px;
}
.payment-modal .payment-method.row {
  margin: 10px;
}
.payment-modal img {
  width: 300px;
}
.payment-modal .after.detail {
  margin: 20px 0;
}

section.about {
  background-color: var(--section-color-2);
}
.about-me-img {
  background-image: url("images/yuye_photo_2.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 300px 100px;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 4px;
}
.about .me-intro {
  color: #719fe4;
}
